
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "gender-component",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    selectedOption: String
  },
  data() {
    return {
      genders: ""
    };
  },
  created() {
    this.getGenders();
  },
  methods: {
    getGenders() {
      //Fetch list of Genders from the database
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.GENDER_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.genders = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    }
  },
  computed: {
    mainSelectedOption() {
      return this.selectedOption;
    }
  }
});
