
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "create-cooperative-step-3",
  components: {
    Field,
    ErrorMessage
  },
  props: {
    cooperativeRates: Array
  },
  data() {
    return {
      rates: [
        {
          value: "",
          publicId: ""
        }
      ],
      totalPremium: 0.0 as number
    };
  }
});
