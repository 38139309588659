
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";
import Address from "@/components/reusable/Address.vue";

export default defineComponent({
  name: "edit-cooperative-step-1",
  components: {
    Field,
    ErrorMessage,
    Address
  },
  props: {
    bank: Object,
    banks: Array,
    bankDetails: Object,
    cooperative: Object
  },
  data() {
    return {
      cooperativeLogo: ""
    };
  },
  methods: {
    acceptNumberOnly(amount) {
      return variables.acceptNumberOnly(amount);
    },
    convertImagetoBase64(event) {
      const selectedImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.cooperativeLogo = reader.result as string;
      };

      reader.onerror = function (error) {
        variables.toastAlert(error, "error");
      };
    }
  },
  computed: {
    mainCooperative() {
      return this.cooperative;
    },
    mainBank() {
      return this.bank;
    },
    mainBankDetails() {
      return this.bankDetails;
    }
  }
});
